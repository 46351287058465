.button-container {
  display: flex;

  .button-container--button {
    margin-right: 1rem;

    .button-container--button--sortIcons {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      line-height: 0;
      position: relative;
      top: -2px;

      svg {
        margin-top: -.2rem;
        margin-bottom: -.2rem;
        margin-right: .5rem;
      }
    }
  }
}

.active {
  color: #5cbd7b;
}

.evenRow {
  background-color: #f9f9f9;
}

.oddRow {
  background-color: #ffffff;
}
